import React, {useEffect} from 'react';
import Link from 'next/link';
import {GetStaticProps} from 'next';
import {useTranslation} from 'next-i18next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {webContentService} from '@/firebase/webContentService';
import {useRouter} from 'next/router';

import NewsletterForm from '@/components/forms/NewsletterForm';
import PurchaseLink from '@/components/ui/links/PurchaseLink';
import TextWithHighlight from '@/components/ui/text/TextWithHighlight';
import FormatIcons from '@/components/ui/FormatIcons';
import styles from '@/styles/pages/home.module.scss';
import CustomImage from '@/components/ui/images/CustomImage';
import ImageCarousel from '@/components/ui/Carousel';
import ReviewsCarousel from '@/components/ui/ReviewsCarousel';
import Cookies from 'js-cookie';
import CookieConsent from '@/components/CookieConsent';
import {orderService} from '@/firebase/orderService';
import {set} from 'date-fns';
import {emailService} from '@/services/emailService';
import ClickableImage from '@/components/ui/images/ClickableImage';
import {logToServerService} from "@/services/logToServerService";
// import CustomHead from '@/components/layout/CustomHead';
declare let gtag: any;

function text(text: any) {
    if (!text) return '';
    return text.includes('*') ? <TextWithHighlight text={text}/> : text;
}

function ArrowLink({text, navigateTo = 'store'}: any) {
    return (
        <Link href={`/${navigateTo}`} className={styles['arrow-link']}>
            <small>&#8249;</small>
            <span> {text} </span>
        </Link>
    );
}

// debugger;
export default function HomePage({content}: any) {
    const {t} = useTranslation('common');
    const router = useRouter();
    const {
        aboutAuthor,
        callToBuy,
        header,
        newsletter,
        bookOffer,
        purchase,
        wealth,
        slideImages,
        reviews,
    } = content;

    const [showConsent, setShowConsent] = React.useState(false);
    const [hasUserConsented, setHasUserConsented] = React.useState(false);

    useEffect(() => {
        // orderService.saveSummarizedData();
        let fullPath = window.location.href;
        debugger;

        window.location.href=fullPath+'/audioBook';
        //save a cookie with the initial path
        document.cookie = `initialPath=${fullPath}; max-age=31536000; path=/`;
        // check if the user has already given consent
        const consentCookie = 'true'; //TODO, uncomment when selling to europe Cookies.get('userHasConsented');
        if (consentCookie === 'true') {
            setHasUserConsented(true);
            setShowConsent(false);
        } else {
            setShowConsent(true);
        }
    }, []);

    // useEffect(() => {
    //       async function getOrders() {
    //       const orders = await orderService.getOrderAttempts();
    //       //map orders and look for one with shippingInfo
    //       // console.log(orders);
    //       //log all orders apart from ones with shippingInfo === gadi@freethinking.org
    //       const order = orders.map(async (order: any) => {
    //         if (order.shippingInfo?.email !== 'gadi@freethinking.org'){
    //           // console.log(order.paymentInfo?.email);
    //           if (order.paymentInfo?.email === 'razliormail@gmail.com'){
    //             console.log(order);
    //             //save to confirmed orders
    //             // await orderService.saveConfirmedOrder(order, order.id);
    //             // await emailService.sendPurchaseEmail(order, 'he');
    //             // await orderService.saveOrdersToSheets();

    //           }

    //         }
    //       });

    //       // if (orders){
    //       // const order = orders.find(
    //       //   (order: any) => order.shippingInfo?.email === 'igorbryn@gmail.com'
    //       // );
    //       // console.log(order);
    //     }

    //     getOrders();
    //   }, []);

    useEffect(() => {
        const {query}: any = router;
        // debugger;
        gtag('js', new Date());
        // Only run this if the user has given consent
        if (hasUserConsented) {
            // Loop over all query parameters
            for (let key in query) {
                // Only set a new cookie if one doesn't already exist
                if (!Cookies.get(key)) {
                    // Set a cookie to expire in 7 days
                    Cookies.set(key, query[key], {expires: 7});
                }
            }
            // gtag('event', 'returning_visitor', {
            //   send_to: 'AW-10930465774/uzjdCITK78oYEO7Xhtwo',
            //   'value': 1,  // optional
            //   // 'currency': 'USD',    // optional
            //   // 'user_id': 'USER_UNIQUE_ID'  // optional, if you have a unique user ID to track
            // });
        } else {
            // gtag('event', 'new_user', {
            //   send_to: 'AW-10930465774/uzjdCITK78oYEO7Xhtwo',
            //   'value': 1,  // optional
            //   // 'currency': 'USD',    // optional
            //   // 'user_id': 'USER_UNIQUE_ID'  // optional, if you have a unique user ID to track
            // });
        }
    }, [router, hasUserConsented]);

    const handleConsent = (val: string) => {
        val === 'true' ? setHasUserConsented(true) : setHasUserConsented(false);
        setShowConsent(false);
        Cookies.set('userHasConsented', val, {expires: 365}); // consent cookie expires in 1 year
    };

    return (
        <div></div>
        // <>
        //
        //
        //     <main className={`no-padding-bottom no-padding-top ${styles.page}`}>
        //         {/* headline */}
        //         {header && (
        //             <section
        //                 className={`full-width  ${styles.headline} ${styles.dark} ${styles['change-direction']}`}
        //             >
        //                 <div className={styles['text-container']}>
        //                     <div className={styles.headers}>
        //                         <h1>{header.title}</h1>
        //                         <h2>{header.subtitle}</h2>
        //                     </div>
        //                     <p>{header.text}</p>
        //                     <div className={styles.links}>
        //                         <PurchaseLink
        //                             translatedText={header.buttonText}
        //                             className={`${styles['buy-btn']} home-section-headline`}
        //                         />
        //                         <center>
        //
        //                                 <div >
        //                                     <a href="https://content.freethinking.org/" target="_blank" rel="noopener noreferrer">
        //                                         תוכן חינמי על ״החופש לחשוב״
        //                                     </a>
        //                                     {/*<ArrowLink>*/}
        //                                     {/*</ArrowLink>*/}
        //
        //                                 </div>
        //
        //                         </center>
        //                         {/*<ArrowLink text={`${t('more info')}`}/>*/}
        //                     </div>
        //                 </div>
        //
        //                 <ClickableImage
        //                     imgObg={header.image}
        //                     // isBg={true}
        //                     // imgClass={styles['bg-img']}
        //                 />
        //                 <div className={`${styles.formats} `}>
        //                     <FormatIcons/>
        //                 </div>
        //             </section>
        //         )}
        //
        //         {/* bookOffer */}
        //         {bookOffer && (
        //             <section
        //                 className={`full-width no-padding ${styles.bookOffer} ${styles['change-direction']}`}
        //             >
        //                 <div className={styles['text-container']}>
        //                     <h3>{bookOffer.title}</h3>
        //                     <p>{bookOffer.text}</p>
        //                 </div>
        //                 <ImageCarousel images={slideImages}/>
        //             </section>
        //         )}
        //
        //         {/* wealth */}
        //         {wealth && (
        //             <>
        //                 <section
        //                     className={`${styles.wealth} ${styles['change-direction']}`}
        //                 >
        //                     <div className={styles['text-container']}>
        //                         <div className={styles.headers}>
        //                             <h3>{wealth.title}</h3>
        //                             <h4>{wealth.subtitle}</h4>
        //                         </div>
        //
        //                         <div className={styles.links}>
        //                             <PurchaseLink
        //                                 translatedText={wealth.buttonText}
        //                                 className={`${styles['buy-btn']} home-section-wealth`}
        //                             />
        //                             <ArrowLink text={`${t('more info')}`}/>
        //                         </div>
        //                     </div>
        //                     <div>
        //                         <CustomImage imgObg={wealth.image}/>
        //                         <h3>{t('alon gonen founder')}</h3>
        //                     </div>
        //                 </section>
        //             </>
        //         )}
        //
        //         {/* quotes */}
        //         <section className={`${styles.quotes}`}>
        //             <img src="/icons/Subtitle.webp" alt="quote icon"/>
        //             {/* <p>{text(aboutAuthor.quote1)}</p> */}
        //             <cite>{text(aboutAuthor.quote1)}</cite>
        //             <cite>{text(aboutAuthor.quote2)}</cite>
        //
        //             {/* <p>{text(aboutAuthor.quote2)}</p> */}
        //         </section>
        //
        //         {/* callToBuy */}
        //         {callToBuy && (
        //             <section
        //                 className={`full-width ${styles['call-to-buy']} ${styles.dark}`}
        //             >
        //                 <div className={styles['text-container']}>
        //                     <h3>{callToBuy.title}</h3>
        //                     <p>{callToBuy.text}</p>
        //                     <div className={styles.links}>
        //                         <PurchaseLink
        //                             translatedText={callToBuy.buttonText}
        //                             className={`${styles['buy-btn']} home-section-callToBuy`}
        //                         />
        //                         <ArrowLink text={`${t('more info')}`}/>
        //                     </div>
        //                 </div>
        //                 <ClickableImage
        //                     imgObg={callToBuy.image}
        //                     // isBg={true}
        //                     // imgClass={styles['bg-img']}
        //                 />
        //                 {/* <CustomImage imgObg={callToBuy.image} /> */}
        //             </section>
        //         )}
        //
        //         {/* purchase */}
        //         {purchase && (
        //             <section className={styles.purchase}>
        //                 <div className={styles['text-container']}>
        //                     <p>{purchase.preText}</p>
        //                     <h3>{purchase.title}</h3>
        //                     <ArrowLink text={purchase.linkText}/>
        //                 </div>
        //                 <CustomImage imgObg={purchase.image}/>
        //             </section>
        //         )}
        //
        //         {/* reviews */}
        //         <div className={styles.reviews}>
        //             <h3>{reviews.title}</h3>
        //             <ReviewsCarousel/>
        //         </div>
        //
        //         {/* newsletter */}
        //         {newsletter && (
        //             <section className={`full-width ${styles.newsletter} ${styles.dark}`}>
        //                 <div className={styles['text-container']}>
        //                     <p>{newsletter.text}</p>
        //                     <h3>{newsletter.title}</h3>
        //                 </div>
        //                 <NewsletterForm/>
        //             </section>
        //         )}
        //         {/* {showConsent && <CookieConsent onConsent={handleConsent} />} */}
        //     </main>
        // </>
    );
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
    const content = await webContentService.getPageContent(locale, 'homePage');
    // const bookFormats = await bookService.getBookFormats();

    return {
        props: {
            content,
            // bookFormats,
            ...(await serverSideTranslations(locale ?? 'en', [
                'common',
                'meta',
                'form',
                'lang',
            ])),
        },
        revalidate: 60,
    };
};
