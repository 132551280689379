import React from 'react';

export default function TextWithHighlight({ text }: { text: string }) {
  const regex = /\*(.*?)\*/g;
  let match;
  let lastIndex = 0;
  const segments: string[] = [];

  while ((match = regex.exec(text))) {
    const segmentBefore = text.substring(lastIndex, match.index);
    const segmentHighlight = match[1];
    segments.push(segmentBefore);
    segments.push(segmentHighlight);
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < text.length) {
    const segmentAfter = text.substring(lastIndex);
    segments.push(segmentAfter);
  }

  return (
    <>
      {segments.map((segment, index) => {
        if (segments.indexOf(segment) % 2 === 1) {
          const highlightedText = segment;
          return (
            <React.Fragment key={index}>
              <b>{highlightedText}</b>
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={index}>{segment}</React.Fragment>;
        }
      })}
    </>
  );
}
